const lastNameInput = document.getElementById('last-name')
const firstNameInput = document.getElementById('first-name')
const companyInput = document.getElementById('company')
const mailInput = document.getElementById('mail')
const phoneInput = document.getElementById('phone')
const messageInput = document.getElementById('message')
const conditionsInput = document.getElementById('conditions')

const sendButton = document.getElementById('form-send-button')

let lastName = ''
let firstName = ''
let company = ''
let mail = ''
let phone = ''
let message = ''
let conditions = false

let dataIsValid = false
let isLoading = false

const checkInputs = () => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  if (
    lastName.trim() !== '' &&
    firstName.trim() !== '' &&
    company.trim() !== '' &&
    mail.trim() !== '' &&
    emailPattern.test(mail) &&
    phone.trim() !== '' &&
    message.trim() !== '' &&
    conditions
  ) {
    sendButton.classList.remove('disabled')
    dataIsValid = true
  } else {
    sendButton.classList.add('disabled')
    dataIsValid = false
  }
}

const resetForm = () => {
  lastNameInput.value = ''
  firstNameInput.value = ''
  companyInput.value = ''
  mailInput.value = ''
  phoneInput.value = ''
  messageInput.value = ''
  conditionsInput.checked = false

  lastName = ''
  firstName = ''
  company = ''
  mail = ''
  phone = ''
  message = ''
  conditions = false

  dataIsValid = false
}

lastNameInput.addEventListener('input', (e) => {
  lastName = e.target.value
  checkInputs()
})

firstNameInput.addEventListener('input', (e) => {
  firstName = e.target.value
  checkInputs()
})

companyInput.addEventListener('input', (e) => {
  company = e.target.value
  checkInputs()
})

mailInput.addEventListener('input', (e) => {
  mail = e.target.value
  checkInputs()
})

phoneInput.addEventListener('input', (e) => {
  phone = e.target.value
  checkInputs()
})

messageInput.addEventListener('input', (e) => {
  message = e.target.value
  checkInputs()
})

conditionsInput.addEventListener('change', (e) => {
  conditions = e.target.checked
  checkInputs()
})

sendButton.addEventListener('click', (e) => {
  e.preventDefault()
  if (dataIsValid && !isLoading) {
    const formData = {
      lastName: lastName,
      firstName: firstName,
      company: company,
      mail: mail,
      phone: phone,
      message: message
    }

    sendButton.classList.add('loading')

    ;(async () => {
      try {
        const res = await fetch('http://localhost:8088/send-email', { method: 'POST', headers: {"Content-Type": 'application/json'}, body: JSON.stringify(formData) })
  
        if (res.status !== 200) {
          const errorText = document.querySelector('.form-error-text')
          if (errorText) errorText.style.display = 'block'
        }
  
        sendButton.classList.remove('loading')
        sendButton.classList.add('disabled')
        isLoading = false
        
        if (res.status === 200) {
          resetForm()
          const errorText = document.querySelector('.form-error-text')
          if (errorText) errorText.style.display = ''
          sendButton.innerText = 'Envoyé !'
          sendButton.style.color = '#1c0e86'
          setTimeout(() => {
          sendButton.style.color = ''
            sendButton.innerText = 'Envoyer'
          }, 3000)
        }
      } catch (error) {
        sendButton.classList.remove('loading')
        sendButton.classList.remove('disabled')
        isLoading = false
        const errorText = document.querySelector('.form-error-text')
        if (errorText) errorText.style.display = 'block'
      }
    })()
  }
})

const dropdowns = document.querySelectorAll('.commitments-dropdown')

let selectedIndex = null

dropdowns.forEach((dropdown, i) => {
  dropdown.addEventListener('click', () => {
    if (selectedIndex === i) {
      selectedIndex = null
    } else {
      selectedIndex = i
    }

    processDropdowns()
  })
})

const processDropdowns = () => {
  dropdowns.forEach((dropdown, i) => {
    if (selectedIndex === i) {
      dropdown.classList.add('visible')
    } else {
      dropdown.classList.remove('visible')
    }
  })
}

(async () => {
  const carouselImages = document.querySelectorAll(".demo-carousel-main-image");
  const leftArrow = document.getElementById("demo-carousel-arrow-left");
  const rightArrow = document.getElementById("demo-carousel-arrow-right");

  const carouselPagination = document.querySelector(
    ".demo-carousel-pagination"
  );

  const carousel = document.querySelector('.demo-carousel')

  let currentIndex = 0;

  for (let i = 0; i < carouselImages.length; i++) {
    await new Promise((resolve, reject) => {
      carouselPagination.innerHTML += `<div class="demo-carousel-pagination-circle ${
        i === currentIndex ? "selected" : ""
      }"></div>`;
      resolve();
    });
  }

  const allPaginationCircles = document.querySelectorAll(
    ".demo-carousel-pagination-circle"
  );

  const updateCircles = () => {
    allPaginationCircles.forEach((circle, index) => {
      if (index === currentIndex) {
        circle.classList.add("selected");
      } else {
        circle.classList.remove("selected");
      }
    });
  };

  allPaginationCircles.forEach((circle, index) => {
    circle.addEventListener("click", () => {
      currentIndex = index;
      updateImage();
      updateCircles();
    });
  });

  const updateImage = () => {
    carouselImages.forEach((image, index) => {
      if (index === currentIndex) {
        image.classList.add("visible");
      } else {
        image.classList.remove("visible");
      }
    });
  };

  const handleNext = () => {
    if (currentIndex === carouselImages.length - 1) {
      currentIndex = 0;
    } else {
      currentIndex++;
    }
    updateImage();
    updateCircles();
  };

  const handlePrevious = () => {
    if (currentIndex === 0) {
      currentIndex = carouselImages.length - 1;
    } else {
      currentIndex--;
    }
    updateImage();
    updateCircles();
  };

  leftArrow.addEventListener("click", handlePrevious);
  rightArrow.addEventListener("click", handleNext);

  let touchstartX = 0;
  let touchendX = 0;

  function checkDirection() {
    if (touchendX < touchstartX) handlePrevious();
    if (touchendX > touchstartX) handleNext();
  }

  carousel.addEventListener("touchstart", (e) => {
    touchstartX = e.changedTouches[0].screenX;
  });

  carousel.addEventListener("touchend", (e) => {
    touchendX = e.changedTouches[0].screenX;
    checkDirection();
  });

  if (carouselImages.length < 2) {
    leftArrow.style.opacity = '0'
    leftArrow.style.cursor = 'default'
    rightArrow.style.opacity = '0'
    rightArrow.style.cursor = 'default'
  }
})();

const arrowLeft = document.getElementById("users-carousel-arrow-left");
const arrowRight = document.getElementById("users-carousel-arrow-right");
const carouselItems = document.querySelectorAll(".users-carousel-logo");
const carousel = document.querySelector(".users-carousel-content");

arrowLeft.addEventListener("click", () => {
  carousel.scrollBy({ left: -(3 * 150 + 3 * 56) })
});

arrowRight.addEventListener("click", () => {
  carousel.scrollBy({ left: 3 * 150 + 3 * 56 })
});

const allItems = document.querySelectorAll(".comments-item");
const commentsCarousel = document.querySelector(".comments-carousel-content");
const leftArrow = document.getElementById("comments-carousel-arrow-left");
const rightArrow = document.getElementById("comments-carousel-arrow-right");

let currentIndex = 0;

const scrollNext = () => {
  const carouselWidth = commentsCarousel.getBoundingClientRect().width;
  if (window.innerWidth > 1200) {
    commentsCarousel.scrollBy({ left: carouselWidth / 3 + 2 });
  } else {
    commentsCarousel.scrollBy({ left: carouselWidth - 9 });
  }
};

const scrollPrevious = () => {
  const carouselWidth = commentsCarousel.getBoundingClientRect().width;
  if (window.innerWidth > 1200) {
    commentsCarousel.scrollBy({ left: -carouselWidth / 3 + 2 });
  } else {
    commentsCarousel.scrollBy({ left: -(carouselWidth - 9) });
  }
};

const sizeItems = () => {
  allItems.forEach((item) => {
    if (window.innerWidth > 768) {
      const carouselWidth = commentsCarousel.getBoundingClientRect().width;
      if (window.innerWidth > 1200) {
        item.style.width = `${(carouselWidth - 4 * 24) / 3 + 4}px`;
      } else {
        item.style.width = `${carouselWidth - 2 * 24 + 10}px`;
      }
    } else {
      item.style.width = "";
    }
  });
};

window.addEventListener("resize", () => {
  sizeItems();
  commentsCarousel.scrollTo({ left: 0 });
});

rightArrow && rightArrow.addEventListener("click", scrollNext);
 rightArrow && leftArrow.addEventListener("click", scrollPrevious);

sizeItems();

document.querySelectorAll('.extension-button').forEach((button) => {
  button.addEventListener('mouseover', () => {
    button.innerHTML = 'Bientôt disponible'
  })

  button.addEventListener('mouseout', () => {
    button.innerHTML = 'Téléchargez le YETIC Score'
  })

  button.addEventListener('click', (e) => {
    e.preventDefault()
  })
})

document.querySelectorAll('.forbidden').forEach((button) => {
  button.addEventListener('click', (e) => {
    e.preventDefault()
  })

  button.addEventListener('mouseover', () => {
    button.innerHTML = 'Bientôt disponible'
  })

  button.addEventListener('mouseout', () => {
    button.innerHTML = 'Téléchargez YETIC Score'
  })
})
